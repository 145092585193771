import React, { useState } from "react";
import CustomModal from "../CustomModal";
import { Form, Formik } from "formik";
import Button from "../Button";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login } from "Redux/Actions/Auth";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { STATUS_CODES } from "Services/Api/Constants";
import InputFieldFormik from "../InputFieldFormik";
import { IMAGES } from "Shared/Images";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
import { openLoginModal } from "Redux/Actions/user";
import { setSocket } from "Redux/Actions/Socket";


const LoginModal = ({
  open = false,
  setOpen = () => {},
  toggleForgotModal = () => {},
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const socketInstance = useSelector((state)=> state?.socket?.socketManager || "");
  const isRegistrationOpen = useSelector(state =>state?.common?.whiteLabelData?.isRegistrationOpen || false);



  const handleSubmit = (values) => {
    dispatch(
      login(values, (response) => {
        if (enqueueSnackbar) {
          enqueueSnackbar(t(response.msg), {
            variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        if(response.statusCode === STATUS_CODES.SUCCESS){
          setOpen(false);
          dispatch(openLoginModal(false));
          if(socketInstance){
            socketInstance.establishConnection(response.data.token, (socket)=> dispatch(setSocket(socket)));     
          }
        }
      })
    );
  };

  const validate = Yup.object({
    userName: Yup.string()
      .required(t("no-username"))
      .min(3, t("username-short")),
    password: Yup.string()
      .required(t("required"))
      .matches(
        /^(?=.*[a-zA-Z!@#\$%\^&\*])(?=.*[0-9]).{6,}$/,
        t("password-val")
      ),
  });

  return (
    <CustomModal isOpen={open} title={t("login")} handleToggle={() => { setOpen(!open); if(open){ dispatch(openLoginModal(false)) }  }}>
      <>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validate}
          initialValues={{
            userName: "",
            password: "",
          }}
        >
          {(props) => (
            <>
              <Form className="text-center">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <InputFieldFormik
                        className="form-control me-1"
                        label={t("username-email")}
                        name={"userName"}
                        type={"text"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group password-view">
                      <InputFieldFormik
                        className="form-control me-1"
                        label={t("password")}
                        name={"password"}
                        type={showPassword ? "text" : "password"}
                      />
                      <span className="pwd-view-img" onClick={(prev) => setShowPassword(!showPassword)}>
                        <img
                          src={showPassword ? IMAGES.eyeOpen : IMAGES.eyeClose}
                          className="img-fluid"
                          alt="imgOpen"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex justify-content-center">
                      <Button className="btn btn-md btn-primary m-2" type="submit">
                        {t("login")}
                      </Button>
                      {isRegistrationOpen ?
                      <Button
                        className="btn btn-md btn-red m-2"
                        onClick={() => {
                          setOpen(false);
                          dispatch(openLoginModal(false));
                          history.push(ROUTES.REGISTER);
                        }}
                      >
                        {t("register")}
                      </Button> : null}
                    </div>
                  </div>
                  {/* <div className="col-md-12 justify-content-center mt-3 cursor-ptr">
                    <span
                      className="text-dark"
                      onClick={() => {
                        toggleForgotModal();
                        setOpen(false);
                        dispatch(openLoginModal(false));
                      }}
                    >
                      {t("forgot-password")}
                    </span>
                  </div> */}
                </div>
              </Form>
            </>
          )}
        </Formik>
      </>
    </CustomModal>
  );
};

export default LoginModal;
