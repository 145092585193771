import { OPEN_LOGIN_MODAL, SAVE_BANK_ACCOUNTS, SET_USER_DETAILS, SET_USER_LANG } from "Redux/Actions/user";

const initialState = {
    details : {},
    bankAccounts : [],
    lang : "en",
    loginModal : false
  };
  const UserReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_USER_DETAILS : {
        return {
            ...state,
            details : action.payload
        };
      }
      case SAVE_BANK_ACCOUNTS : {
        return {
          ...state,
            bankAccounts : action.payload
        };
      }

      case SET_USER_LANG : {
        return {
          ...state,
          lang : action.payload
        };
      }

      case OPEN_LOGIN_MODAL : {
        return {
          ...state,
          loginModal : action.payload
        };
      }
  
      default: {
        return state;
      }
    }
  };
  
  export default UserReducer;
  