import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import reactManifest from "react-manifest";

import { AUTH_ROUTES } from "./AuthRoutes";
import { PRIVATE_ROUTES } from "./PrivateRoutes";
import { PUBLIC_ROUTES } from "./PublicRoutes";
import { STATUS_CODES } from "Services/Api/Constants";
import { fetchSiteSettingsCmsContent } from "Redux/Actions/CMS";
import { createElementFromHTML } from "Shared/Utilities";

const manifestDetails = {
  short_name: "Bolantangkas 2",
  name: "Bolantangkas 2",
  icons: [
    {
      src: "favicon.ico",
      sizes: "48x48",
      type: "image/x-icon",
    },
    {
      src: "logo192.png",
      type: "image/png",
      sizes: "192x192",
    },
    {
      src: "logo512.png",
      type: "image/png",
      sizes: "512x512",
    },
  ],
  start_url: ".",
  display: "standalone",
  theme_color: "#000000",
  background_color: "#ffffff",
};

const pathToRegexp = require("path-to-regexp");

const DocumentTitle = ({ isAuthenticated = false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const matchedRoute = PUBLIC_ROUTES.concat(isAuthenticated ? PRIVATE_ROUTES : AUTH_ROUTES).find((route) =>
    pathToRegexp(route.path).test(location.pathname)
  );
  const title = matchedRoute ? matchedRoute.title : "";
  const whiteLabelData = useSelector((state) => state?.common?.whiteLabelData || {});

  useEffect(() => {
    if (document) {
      fetchContent();
    }
  }, []);

  useEffect(() => {
    if (whiteLabelData) {
      manifestDetails.short_name = whiteLabelData?.brandName;
      manifestDetails.name = whiteLabelData?.brandName;
      reactManifest.update(manifestDetails, "#manifest-placeholder");
      if(whiteLabelData?.brandImage){
        changeFavicon(whiteLabelData?.brandImage);
      }
    }
  }, [whiteLabelData]);

  function changeFavicon(src) {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = src;
  }

  const fetchContent = () => {
    dispatch(
      fetchSiteSettingsCmsContent((response) => {
        if (response?.statusCode == STATUS_CODES.SUCCESS) {
          showContent(response?.data?.site);
        }
      })
    );
  };

  const showContent = (content) => {
    let html = {
      metaKeywords: content?.metaKeywords,
      metaDescription: content?.metaDescription,
      googleAnalytics: content?.googleAnalytics,
      liveChatWeb: content?.liveChatWeb,
      liveChatMobile: content?.liveChatMobile,
      tailHeader: content?.tailHeader,
    };

    let head = document.getElementsByTagName("head")[0];

    Object.values(html).map((item) => {
      let htmlMeta = item?.trim() && createElementFromHTML(item);
      htmlMeta && head.append(...htmlMeta);
    });

    if (content?.canonical?.trim()) {
      let tag = createElementFromHTML(content.canonical);
      head.prepend(...tag);
    }

    if (content?.topHeader?.trim()) {
      let tag = createElementFromHTML(content.topHeader);
      head.prepend(...tag);
    }

    let body = document.getElementsByTagName("body")[0];
    if (content?.topBody?.trim()) {
      let tag = createElementFromHTML(content.topBody);
      body.prepend(...tag);
    }
    if (content?.tailBody?.trim()) {
      const script = document.createElement("script");
      script.innerHTML = content?.tailBody?.trim();
      body.append(script);
      // let tag = createElementFromHTML(content.tailBody);
      // body && body.append(...tag);
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>{whiteLabelData?.brandName || ""}</title>
        <meta title={title} />
      </Helmet>
    </HelmetProvider>
  );
};

export default DocumentTitle;
