import { TextField } from '@mui/material'
import React from 'react'

const TextInputField = ({type = "text",required = false,id = "", label = "",value = "", defaultValue = "", variant = "",onChange = ()=>{}}) => {
  return (
    <div>
    <TextField
    type={type}
    required={required}
    id={id}
    label={label}
    defaultValue={defaultValue}
    variant={variant}
    onChange={onChange}
    />
    </div>
  )
}

export default TextInputField