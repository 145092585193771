import { SET_FOOTER_TEXT_CONTENT, SET_RUNNING_TEXT_CONTENT, SET_SITE_TITLE, SET_WELCOME_CONTENT } from "Redux/Actions/constants";

const initialState = {
  welcomePopup : {},
  footerText : "",
  siteTitle : ""
};
const CMSReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WELCOME_CONTENT : {
      return {
        ...state,
        welcomePopup: action.payload,
      };
    }
    case SET_RUNNING_TEXT_CONTENT : {
      return {
        ...state,
        runningText: action.payload,
      };
    }

    case SET_FOOTER_TEXT_CONTENT : {
      return {
        ...state,
        footerText: action.payload,
      };
    }

    case SET_SITE_TITLE : {
      return {
        ...state,
        siteTitle: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default CMSReducer;
