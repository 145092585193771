import { fetchFooterPagesCmsContent } from 'Redux/Actions/CMS';
import { CMS_SECTION_TYPE } from 'Shared/Constants';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

const HelpCenter = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [content,setContent] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
   
    dispatch(fetchFooterPagesCmsContent({ type : CMS_SECTION_TYPE.HELP_CENTRE },(response)=>{
       setContent(response?.data?.description || "");
    }))

  }, []);
  return (
    <div className="py-4 py-md-5 bg_img_desiny min-vh-50">
      <div className="container">
        <h1 className='h3'>{t("help-center")}</h1>
        <div dangerouslySetInnerHTML={ {__html: content}}></div>
      </div>
    </div>)
}

export default HelpCenter