import { takeLatest, all, put } from "redux-saga/effects";

import { CHANGE_PASSWORD, FORGOT_PWD, LOGIN, LOGOUT, REGISTER, RESET_PASSWORD, VERIFY_OTP, updateReducerAuthToken } from "../Actions/Auth";

import { startLoading, stopLoading } from "Redux/Actions/loader";
import { postRequestNoAuth, updateAuthToken,putRequest } from "Shared/Axios";
import { API_URLS, STATUS_CODES } from "Services/Api/Constants";
import { getUserDetails, setUserDetails } from "Redux/Actions/user";
import { store } from "Redux/Store";
import { setSocket} from "Redux/Actions/Socket";


function* login({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    const { data } = yield postRequestNoAuth({
      API: API_URLS.LOGIN,
      DATA: payload,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(updateReducerAuthToken(data.data.token));
      yield put(getUserDetails());
      updateAuthToken(data.data.token);
    } else {
      callback(data);
    }
  } catch (error) {
    console.log(error.data,"error");
    callback(error.data);
    // fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* forgotPassword({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    const { data } = yield postRequestNoAuth({
      API: API_URLS.FORGOTPASSWORD,
      DATA: payload,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
    } else {
      callback(data);
    }
  } catch (error) {
    console.log(error.data,"error");
    callback(error.data);
    // fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* registerUserSaga({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    const { data } = yield postRequestNoAuth({
      API: API_URLS.REGISTER,
      DATA: payload,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      callback(data);
    }
  } catch (error) {
    callback(error?.data);
    // fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* changePassword({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    const apiResponse = yield putRequest({
      API: API_URLS.CHANGEPASSWORD,
      DATA: payload,
    });
    let { data , response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    }else if (data?.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      callback(response?.data);
    }
  } catch (error) {
    callback(error?.data);
    // fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* resetPassword({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    const apiResponse = yield putRequest({
      API: API_URLS.RESETPASSWORD,
      DATA: payload,
    });
    let { data , response } = apiResponse;

    if (response?.status == STATUS_CODES.UNAUTHORIZED) {
      yield put(logout());
    }else if (data?.statusCode == STATUS_CODES.SUCCESS) {
      callback(data);
      yield put(stopLoading());
    } else {
      callback(response?.data);
    }
  } catch (error) {
    callback(error?.data);
    // fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* verifyOtp({ payload, callback = () => { }}) {
  try {
    yield put(startLoading());
    const { data } = yield postRequestNoAuth({
      API: API_URLS.VERIFY_OTP,
      DATA: payload,
    });
    if (data.statusCode == STATUS_CODES.SUCCESS) {
      updateAuthToken(data.data.token);
      yield put(updateReducerAuthToken(data.data.token));
      yield put(getUserDetails());
      yield put(stopLoading());
      callback(data);
    } else {
      callback(data);
    }
  } catch (error) {
    callback(error.data);
    // fail("Something went wrong");
  } finally {
    yield put(stopLoading());
  }
}

function* logout(){
  const emptyString = ""
  yield put(updateReducerAuthToken(emptyString));
  yield put(setUserDetails({}));
  const state = store?.getState();
  let  socketManager = state?.socket?.socketManager;
  if(socketManager){
    socketManager.disconnectConnection();
  }
  yield put(setSocket(""));  
}

function* watchAuth() {
  yield all([
      takeLatest(LOGIN,login),
     takeLatest(LOGOUT,logout),
     takeLatest(REGISTER,registerUserSaga),
     takeLatest(CHANGE_PASSWORD,changePassword),
     takeLatest(RESET_PASSWORD,resetPassword),
     takeLatest(VERIFY_OTP,verifyOtp),
     takeLatest(FORGOT_PWD,forgotPassword),
    ]);
}

export default watchAuth;
