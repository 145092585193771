import React, { useEffect, useState } from 'react'
import Button from '../Button'
import CustomModal from '../CustomModal'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import './style.scss';

const WelcomeModal = () => {
  const { t } = useTranslation();
  const content = useSelector(state => {
    if(Object.keys(state?.cms?.welcomePopup).length === 0){
      return {
        data: null
      };
    } 

    return {
      data: state?.cms?.welcomePopup?.innerHtml
    };
  });

  const [showModal, setShowModal] = useState(true);
  const [contentModal, setContentModal] = useState(null);

  useEffect(() => {
    if (content.data && showModal) {
      setShowModal(true);
      setContentModal(content.data);
    } else {
      setShowModal(false);
      setContentModal(null);
    }
  }, [content, showModal]);

  return (
    <CustomModal isOpen={showModal} big={true} handleToggle={() => setShowModal(!showModal)}>
      <div className="welcome_content text-black">
        <div className='cms_content' dangerouslySetInnerHTML={{ __html: contentModal }}></div>
        <div className="mt-4 text-center text-center">
          <Button className="btn btn-lg btn-primary" onClick={() => setShowModal(false)}>
            {t("okay")}
          </Button>
        </div>
      </div>
    </CustomModal>
  )
}

export default WelcomeModal