import { vertifyOTP } from 'Redux/Actions/Auth';
import { STATUS_CODES } from 'Services/Api/Constants';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../Button';

const VerifyForgotOtp = ({userName,setActive = ()=>{}}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    
    const [otpValue, setOtpValue] = useState("");
    const [error, setError] = useState("");
    const handleClick = () => {
      if (!otpValue) {
        setError(t("pls-enter-otp"));
        return;
      }
      dispatch(
        vertifyOTP({ otp: otpValue, userName: userName || "" }, (response) => {
          if (enqueueSnackbar) {
            enqueueSnackbar(t(response.msg), {
              variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
          if (response.statusCode === STATUS_CODES.SUCCESS) {
            setActive(3);
          }
        })
      );
    };
  return (
    <>
      <div className='text-start'><span className="text-black ">{t("pls-enter-otp")}</span></div>
      <div className="form-field form-group">
        <input
          className="form-control"
          label={t("enter-otp")}
          placeholder="Enter Otp"
          value={otpValue}
          onChange={(e) => setOtpValue(e.target.value)}
        />
        {error ? <span className="error d-block">{error}</span> : ""}
      </div>
      <Button className="btn btn-md btn-primary me-sm-3 me-2 mb-sm-0" onClick={handleClick}>
        {t("verify-otp")}
      </Button>
      </>
  )
}

export default VerifyForgotOtp