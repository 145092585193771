import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

const useShowResponse = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const showResponse = ({ key, variant = "error", vertical = "top", horizontal = "right", duration = 500 }) => {
    if (enqueueSnackbar) {
      enqueueSnackbar(t(key), {
        variant,
        autoHideDuration: duration,
        anchorOrigin: {
          vertical,
          horizontal,
        },
      });
    }
  };

  return showResponse;
};

export default useShowResponse;
