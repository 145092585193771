export const SET_GAME_LINK = "SET_GAME_LINK";
export const FETCH_GAME_LINK = "FETCH_GAME_LINK";

export const setGameLink = (link)=>{
  return {
    type : SET_GAME_LINK,
    link
  }
}

export const fetchGameLink = (payload,callback = ()=>{})=>{
  return {
    type : FETCH_GAME_LINK,
    payload,
    callback
  }
}