export const GET_WELCOME_CONTENT = "GET_WELCOME_CONTENT";
export const SET_WELCOME_CONTENT = "SET_WELCOME_CONTENT";
export const GET_RUNNING_TEXT_CONTENT = "GET_RUNNING_TEXT_CONTENT";
export const SET_RUNNING_TEXT_CONTENT = "SET_RUNNING_TEXT_CONTENT";
export const GET_FOOTER_PAGES_CMS_CONTENT = "GET_FOOTER_PAGES_CMS_CONTENT";
export const GET_FOOTER_TEXT_CONTENT = "GET_FOOTER_TEXT_CONTENT";
export const SET_FOOTER_TEXT_CONTENT = "SET_FOOTER_TEXT_CONTENT";
export const GET_SITE_SETTINGS_CMS_CONTENT = "GET_SITE_SETTINGS_CMS_CONTENT";
export const SET_SITE_TITLE = "SET_SITE_TITLE";

export const SET_SOCKET = "SET_SOCKET";
export const SET_SOCKET_MANAGER = "SET_SOCKET_MANAGER";