import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { STATUS_CODES } from "Services/Api/Constants";
import { Button, Dropdown, InputFieldFormik } from "Components";
import CustomModal from "Components/Atoms/CustomModal";
import { createBankAccount, editBankAccount, getAccountBrands, getBankAccounts } from "Redux/Actions/user";
import { useTranslation } from "react-i18next";


const AddAccount = ({ edit= false,open = false, toggle = () => {},editdetails = {},setEditDetails= () => {},fetchRecords= () => {},setEdit,accountTypes=[] }) => {
  const {t} = useTranslation();
  const validate = Yup.object({
    accountName: Yup.string()
    .trim()
    .max(60, t("max-60"))
    .matches(/^[a-zA-Z\s]*$/,	t("name-val"))
    .required(t("required")),
  accountNumber: Yup.string()
    .trim()
    .max(30, t("acc-no-max"))
    .matches(/^[0-9]+$/i,t("acc-no-val"))
    .required(t("required")),
  });

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const [error, setError] = useState("");
  const [bankOptions, setBankOptions] = useState([]);
  const [bank, setBank] = useState("");

  const [userAccountBrand,setUserAccountBrand] = useState([]);
  const [userAccountBrandOptions,setUserAccountBrandOptions] = useState([]);

  const handleSubmit = (values) => {

    if(!bank?.value || !userAccountBrand.value){
      setError(t("bank-req"))
      setError({
        account : bank?.value  ? "" : t("account-req"),
        accountBrand : userAccountBrand.value ? "" : t("brand-val")
      })
      return;
    }

    if(edit){
      dispatch(
        editBankAccount({...values,bankId : editdetails._id},(response) => {
          if (enqueueSnackbar) {
            enqueueSnackbar(t(response.msg), {
              variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
              autoHideDuration: 3000,
              anchorOrigin: {
                vertical: "top",
                horizontal: "right",
              },
            });
          }
          if(response.statusCode === STATUS_CODES.SUCCESS){
            setBank("");
            setUserAccountBrand("");
            toggle();
            fetchRecords({selected : 0});
            setEdit(false);
            setEditDetails({});
          }
        })
      );
    }
     else{
       dispatch(
         createBankAccount({...values,accountBrandId : userAccountBrand.value},(response) => {
           if (enqueueSnackbar) {
             enqueueSnackbar(t(response.msg), {
               variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
               autoHideDuration: 3000,
               anchorOrigin: {
                 vertical: "top",
                 horizontal: "right",
               },
             });
           }
           if(response.statusCode === STATUS_CODES.SUCCESS){
             setBank("");
             setUserAccountBrand("");
             toggle();
             setEdit(false);
             fetchRecords({selected : 0});
           }
         })
       );
     }
  };
  
  useEffect(() => {
    return () => {
      setBank("");
      setEdit(false);
      setError("");
      setUserAccountBrand("");
    }
  }, []);

  useEffect(()=>{
    if(edit){
        dispatch(
          getAccountBrands({ accountTypeId : editdetails.accountTypeId }, (response) => {
            if (response.statusCode == STATUS_CODES.SUCCESS) {
              let options = response?.data?.accountBrand?.map((item, index) => ({
                label: item.name,
                value: item._id,
              }));
              let selectedBrand = "";
              options.map((item)=> {
                if(editdetails?.accountBrandId == item.value){
                  selectedBrand = item;
                }
              });
              setUserAccountBrand(selectedBrand);
              setUserAccountBrandOptions(options || []);
            }
          })
        );
    }
  },[editdetails,bank])

  useEffect(()=>{
    if(editdetails){
      let selectedBank = "";
      bankOptions.map((item)=> {
        if(editdetails?.accountTypeId == item.value){
          selectedBank = item;
        }
      });
      setBank(selectedBank);
    }
  },[editdetails,accountTypes,bankOptions])

  useEffect(() => {
    let options = accountTypes?.map((item, index) => ({
      label: item.name,
      value: item._id
    }));
    setBankOptions(options || []);
  }, [accountTypes]);

  const fetchAccountBrands = (id) =>{
    dispatch(
      getAccountBrands({ accountTypeId : id }, (response) => {
        if (response.statusCode == STATUS_CODES.SUCCESS) {
          let options = response?.data?.accountBrand?.map((item, index) => ({
            label: item.name,
            value: item._id,
          }));
          setUserAccountBrandOptions(options || []);
          if(options?.length == 1){
            setUserAccountBrand(options[0]);
            setError((prev) => ({ ...prev, accountBrand: "" }));
          }
        }
      })
    );
  }
  
  const handleToggle = () => {
    toggle(); 
    if(open){
      setBank("");
      setEdit(false);
      setError("");
      setUserAccountBrand("");
      setEditDetails("");
    };
  } 

  const handleClearStates = () => {
    setBank("");
    setEdit(false);
    setError("");
    setUserAccountBrand("");
  }

  return (
    <CustomModal isOpen={open} title={`${edit ? t("edit-account") : t("add-account")}`} handleToggle={handleToggle} clearStates={handleClearStates}>
        <p className="text-dark">{t("acc-msg")}</p>
      <div>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validate}
          initialValues={{
            accountName: edit ? editdetails.accountName : "" ,
            accountNumber: edit ? editdetails.accountNumber : "" ,
          }}
        >
          {(props) => (
            <>
              <Form className="">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group sky_bg_group">
                      <label htmlFor="inputPassword4" className="form-label">
                      {t("account")} <sup className="req_txt">*</sup>
                      </label>
                      <Dropdown
                        className={`me-2 ${edit ? "disabled" : ""}`}
                        id="dropdown"
                        value={bank}
                        name="Account"
                        placeholder={t("choose-account")}
                        options={bankOptions}
                        onChange={(value) => {
                          setError((prev) => ({ ...prev, account: "" }));
                          setBank(value);
                          fetchAccountBrands(value.value);
                          setUserAccountBrand("");
                        }}
                        isDisabled={edit}
                      />
                      {error?.account ? <span className="error d-block">{error?.account}</span> : ""}
                    </div>
                  </div>
                  
                  <div className="col-md-12">
                          <div className="form-group sky_bg_group">
                            <label htmlFor="inputEmail4" className="form-label">
                            {t("account-brand")}<sup className="req_txt">*</sup>
                            </label>
                            <Dropdown
                              className={`me-2 ${edit ? "disabled" : ""}`}
                              id="dropdown"
                              value={userAccountBrand}
                              name="Account"
                              placeholder={t("choose-brand")}
                              options={userAccountBrandOptions}
                              onChange={(value) => {
                                setError((prev) => ({ ...prev, accountBrand: "" }));
                                setUserAccountBrand(value);
                              }}
                              isDisabled={edit}
                            />
                            {error?.accountBrand ? <span className="error d-block">{error.accountBrand}</span> : ""}
                          </div>
                    </div>
                    
                  <div className="col-md-12">
                    <div className="form-group">
                      <InputFieldFormik
                        className="form-control me-1"
                        label={t("account-name")}
                        name={"accountName"}
                        type={"text"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <InputFieldFormik
                        className="form-control me-1"
                        label={t("account-number")}
                        name={"accountNumber"}
                        type={"text"}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-center">
                    <Button className="btn btn-md btn-primary" type="submit">
                      {edit ? t("update") : t("add-account")}
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </CustomModal>
  );
};

export default AddAccount;
