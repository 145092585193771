//hooks
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

//Components
import { Button } from "Components";
import DepositForm from "./Components/DepositForm";
import AccountInfo from "./Components/AccountInfo";
import LastTransactions from "./Components/LastTransactions";
import WithDraw from "./Components/WithDraw";
import BonusTransactions from "./Components/BonusTransactions";

//utils
import { NAVIGATION_BAR } from "Shared/Constants";

//styles
import "./style.scss";

const Deposit = () => {
  const { t } = useTranslation();
  const location = useLocation();
  location.type = location.type ? location.type : NAVIGATION_BAR.DEPOSIT;
  const [active, setActive] = useState(location.type);

  const showActive = (value) => {
    switch (value) {
      case NAVIGATION_BAR.DEPOSIT:
        return <DepositForm setActive={setActive} />;
      case NAVIGATION_BAR.WITHDRAWAL:
        return <WithDraw setActive={setActive} />;
      case NAVIGATION_BAR.LAST_TRANSACTIONS:
        return <LastTransactions />;
      case NAVIGATION_BAR.BONUS_TRANSACTIONS:
        return <BonusTransactions />;
      case NAVIGATION_BAR.ACCOUNT_INFO:
        return <AccountInfo />;
      default:
        return <DepositForm setActive={setActive} />;
    }
  };

  useEffect(() => {
    setActive(location.type);
  }, [location]);

  return (
    <div className="py-4 py-md-5 depost_withdrawal_sec">
      <div className="container">
        <div className="d-flex flex-wrap justify-content-center tabs_list">
          <Button className={`btn btn-secondary ${active == NAVIGATION_BAR.DEPOSIT ? "current" : ""}`} onClick={() => setActive(NAVIGATION_BAR.DEPOSIT)}>
            {t("deposit")}
          </Button>
          <Button className={`btn btn-secondary ${active == NAVIGATION_BAR.WITHDRAWAL ? "current" : ""}`} onClick={() => setActive(NAVIGATION_BAR.WITHDRAWAL)}>
            {t("withdraw")}
          </Button>
          <Button className={`btn btn-secondary ${active == NAVIGATION_BAR.LAST_TRANSACTIONS ? "current" : ""}`} onClick={() => setActive(NAVIGATION_BAR.LAST_TRANSACTIONS)}>
            {t("last-transactions")}
          </Button>
          <Button className={`btn btn-secondary ${active == NAVIGATION_BAR.BONUS_TRANSACTIONS ? "current" : ""}`} onClick={() => setActive(NAVIGATION_BAR.BONUS_TRANSACTIONS)}>
            {t("bonus-transactions")}
          </Button>
          <Button className={`btn btn-secondary ${active == NAVIGATION_BAR.ACCOUNT_INFO ? "current" : ""}`} onClick={() => setActive(NAVIGATION_BAR.ACCOUNT_INFO)}>
            {t("account-info")}
          </Button>
        </div>
        <div className="tab_content">{showActive(active)}</div>
      </div>
    </div>
  );
};

export default Deposit;
