import { START_LOADING, STOP_LOADING } from "Redux/Actions/loader";

const initialState = {
  loader: false,
};
const LoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING: {
      return {
        ...state,
        loader:true
      };
    }
    case STOP_LOADING: {
        return {
          ...state,
          loader:false
        };
    }
    default: {
      return state;
    }
  }
};

export default LoaderReducer;
