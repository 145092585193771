export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_USER_LANG = "SET_USER_LANG";
export const CREATE_BANK_ACCOUNT = "CREATE_BANK_ACCOUNT";
export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const GET_ACCOUNT_TYPES = "GET_ACCOUNT_TYPES";
export const GET_ACCOUNT_BRANDS = "GET_ACCOUNT_BRANDS";
export const SAVE_BANK_ACCOUNTS = "SAVE_BANK_ACCOUNTS";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const EDIT_BANK_ACCOUNT = "EDIT_BANK_ACCOUNT";
export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_BONUS_TRANSACTIONS = "GET_BONUS_TRANSACTIONS";
export const TRANSACTION = "TRANSACTION";
export const CANCEL = "CANCEL";
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";

export const getUserDetails = (callback) => {
    return {
     type : GET_USER_DETAILS,
     callback
    }
 }

export const updateUserDetails = (payload,callback) => {
    return {
     type : UPDATE_USER_DETAILS,
     payload,
     callback
    }
}

export const setUserLang = (payload) => {
    return {
     type : SET_USER_LANG,
     payload
    }
 }

export const setUserDetails = (payload) => {
    return {
     type : SET_USER_DETAILS,
     payload
    }
 }

export const createBankAccount = (payload,callback) => {
    return {
     type : CREATE_BANK_ACCOUNT,
     payload,callback    }
 }

export const deleteBankAccount = (payload,callback) => {
    return {
     type : DELETE_BANK_ACCOUNT,
     payload,callback    }
 }

export const editBankAccount = (payload,callback) => {
    return {
     type : EDIT_BANK_ACCOUNT,
     payload,callback
    }
 }

export const getBankAccounts = (payload,callback) => {
    return {
     type : GET_BANK_ACCOUNTS,
     payload,callback    }
 }

export const getAccountTypes = (callback) => {
    return {
     type : GET_ACCOUNT_TYPES,
     callback    }
}

export const getAccountBrands = (payload,callback) => {
    return {
     type : GET_ACCOUNT_BRANDS,
     payload,
     callback   
    }
}

export const saveBankAccounts = (payload,callback) => {
    return {
     type : SAVE_BANK_ACCOUNTS,
     payload,callback    }
 }

export const getTransactions = (payload,callback) => {
    return {
     type : GET_TRANSACTIONS,
     payload,callback    }
}

export const performTransaction = (payload,callback) => {
    return {
     type : TRANSACTION,
     payload,callback    }
}

export const cancelTransaction = (payload,callback) => {
    return {
     type : CANCEL,
     payload,callback    }
}

export const openLoginModal = (payload) => {
    return {
     type : OPEN_LOGIN_MODAL,
     payload 
    };
}

export const getBonusTransactions = (payload,callback) => {
    return {
     type : GET_BONUS_TRANSACTIONS,
     payload,callback    }
}