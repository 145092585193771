import ChangePassword from "Views/ChangePassword";
import Deposit from "Views/Deposit";
import ReferralLink from "Views/ReferralLink";
import { ROUTES } from "./constants";

export const PRIVATE_ROUTES = [
  {
    path: ROUTES.CHANGE_PASSWORD,
    component: ChangePassword,
    title: "Change Password",
  },
  {
    path: ROUTES.REFERRAL,
    component: ReferralLink,
    title: "Referral Link",
  },
  {
    path: ROUTES.TRANSACTIONS,
    component: Deposit,
    title: "Transactions",
  },
];
