import { Button, InputField, InputFieldFormik } from "Components";
import DropDown from "Components/Atoms/Dropdown";
import { STATUS_CODES } from "Services/Api/Constants";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { getAccountBrands, getBankAccounts, performTransaction } from "Redux/Actions/user";
import Popup from "Components/Atoms/Popup";
import { useTranslation } from "react-i18next";

const WithDraw = ({ }) => {
  const { t } = useTranslation();
  const validate = Yup.object({
    amount: Yup.string().required(t("amount-req")),
  });

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [bankOptions, setBankOptions] = useState([
    { label: "SEBI", value: "SEBI" },
    { label: "MASTER CARD", value: "MASTER CARD" },
    { label: "VISA", value: "VISA" },
  ]);

  // const [account, setAccount] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const [userBankOptions, setUserBankOptions] = useState([]);
  const [accountTypeOptions, setAccountTypeOptions] = useState([]);

  const [accounts, setAccounts] = useState([]);

  const [userAccountType, setUserAccountType] = useState("");
  const [userAccount, setUserAccount] = useState([]);
  const [error, setError] = useState({
    userAccount: "",
    userAccountType: "",
    adminAccountType: "",
    destination: "",
  });

  const [userAccountBrand, setUserAccountBrand] = useState([]);
  const [userAccountBrandOptions, setUserAccountBrandOptions] = useState([]);

  const handleSubmit = (value) => {
    if (!userAccount.value || !userAccountType.value || !userAccountBrand.value) {
      setError((prev) => ({
        ...prev,
        userAccount: userAccount.value ? "" : t("bank-req"),
        userAccountType: userAccountType.value ? "" : t("account-req"),
        userAccountBrand: userAccountBrand.value ? "" : t("brand-val"),
      }));
      return;
    }

    let payload = { ...value, userBankId: userAccount.value, transactionType: 2 };
    payload.amount = payload.amount.replaceAll(/[,]/g, "");
    dispatch(
      performTransaction(payload, (response) => {
        if (response.statusCode === STATUS_CODES.SUCCESS) {
          setUserAccount("");
          setUserAccountType("");
          setOpenModal(true);
        }
        if (enqueueSnackbar) {
          enqueueSnackbar(t(response.msg), {
            variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
      })
    );
  };

  useEffect(()=>{
    dispatch(
      getBankAccounts({ saveAll: true, isListing: true }, (response) => {
        if (response.statusCode == STATUS_CODES.SUCCESS) {
          let options = Object.entries(response?.data?.bankAccount)?.map(([key, value], index) => {
            return {
              label: key,
              value: key,
            };
          });
          setAccountTypeOptions(options || []);
          setAccounts(response?.data?.bankAccount);
        }
      })
    );
  },[])

  const handleUserAccountBrandChange = (selected, userAccountType) => {
    let options = accounts[userAccountType][selected]?.map((item, index) => ({
      label: `${item.accountName} - ${item.accountNumber}`,
      value: item._id,
      index: index,
    }));
    setUserBankOptions(options || []);
    if (options?.length == 1) {
      setUserAccount(options[0]);
      setError((prev) => ({ ...prev, userAccount: "" }));
    }
  };

  const handleUserAccountTypeChange = (selected) => {
    let options = Object.entries(accounts[selected])?.map(([key, value], index) => {
      return {
        label: key,
        value: key,
      };
    });
    setUserAccountBrandOptions(options || []);
    if (options?.length == 1) {
      setUserAccountBrand(options[0]);
      handleUserAccountBrandChange(options[0].value, selected);
      setError((prev) => ({ ...prev, userAccountBrand: "" }));
    }
  };

  return (
    <div className="w-100 form_front pt-4">
      <div className="W-100 form_card bg-white text-start p-4">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validate}
          initialValues={{
            amount: "",
          }}
        >
          {(props) => (
            <>
              <Form
                className="form_pad"
                onChange={(e) => {
                  if (e?.target?.name == "amount") {
                    let str1 = e?.target?.value.trim().replaceAll(/[^0-9,]/g, "");
                    str1 = str1.replaceAll(/[,]/g, "");
                    props.setFieldValue(
                      "amount",
                      str1
                        ?.toString()
                        .split(/(?=(?:\d{3})+(?:\.|$))/g)
                        .join(",")
                    );
                  }
                }}
              >
                <div className="row g-3 ">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group sky_bg_group">
                          <label htmlFor="inputEmail4" className="form-label">
                            {t("account")} <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={userAccountType}
                            options={accountTypeOptions}
                            placeholder={t("choose-account")}
                            onChange={(value) => {
                              setUserAccountBrand("");
                              setUserAccount("");
                              setUserBankOptions([]);
                              setError((prev) => ({ ...prev, userAccountType: "" }));
                              setUserAccountType(value);
                              handleUserAccountTypeChange(value.value);
                            }}
                          />
                          {error?.userAccountType ? <span className="error d-block">{error.userAccountType}</span> : ""}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group sky_bg_group">
                          <label htmlFor="inputEmail4" className="form-label">
                            {t("account-brand")}
                            <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={userAccountBrand}
                            name="Account"
                            placeholder={t("choose-brand")}
                            options={userAccountBrandOptions}
                            onChange={(value) => {
                              setError((prev) => ({ ...prev, userAccountBrand: "" }));
                              setUserAccountBrand(value);
                              handleUserAccountBrandChange(value.value,userAccountType.value);
                            }}
                          />
                          {error?.userAccountBrand ? (
                            <span className="error d-block">{error.userAccountBrand}</span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-field form-group sky_bg_group">
                          <label htmlFor="inputPassword4" className="form-label">
                            {t("account-name-number")}
                            <sup className="req_txt">*</sup>
                          </label>
                          <DropDown
                            className={"me-2"}
                            id="dropdown"
                            value={userAccount}
                            name="Account"
                            placeholder={t("choose-account")}
                            options={userBankOptions}
                            onChange={(value) => {
                              setError((prev) => ({ ...prev, userAccount: "" }));
                              setUserAccount(value);
                            }}
                          />
                          {error?.userAccount ? <span className="error d-block">{error.userAccount}</span> : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <label htmlFor="inputEmail4" className="form-label">
                        {t("withdraw-amount")}
                        <sup className="req_txt">*</sup>
                      </label>
                      <InputFieldFormik
                        name={"amount"}
                        placeholder="Withdrawal Amount"
                        label={t("withdraw-amount")}
                        type={"text"}
                        mih="0"
                        onWheel={(e) => e.target.blur()}
                      />
                    </div>
                  </div>

                  <div className="col-12 text-center text-md-end common_btn mt-md-5">
                    <Button className="btn btn-md btn-primary" type="submit">
                      {t("withdraw")}
                    </Button>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
      <Popup open={openModal} handleToggle={() => setOpenModal(!openModal)} message={t("thank-transaction")} />
    </div>
  );
};

export default WithDraw;
