import { Button, Dropdown, Table } from "Components";
import { cancelTransaction, getTransactions } from "Redux/Actions/user";
import {TRANSACTION_STATUS, TRANSACTION_TYPE_OPTIONS, indexHistory } from "Shared/Constants";
import { IMAGES } from "Shared/Images";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import ReactPaginate from "react-paginate";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { STATUS_CODES } from "Services/Api/Constants";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const LastTransactions = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("");
  const headings = [t("no"), t("date"), t("type"),t("account-name"), t("account-number"), t("amount"),t("status"),t("action")];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalCount, setTotalCount] = useState([]);

  const [data, setData] = useState([]);

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setType("");
    let payload = {
      limit: 10,
      skip: 0,
      fromDate: "",
      toDate: "",
      transactionType: "",
    };
    setCurrentIndex(0);
    dispatch(
      getTransactions(payload, (response) => {
        setData(response?.data?.transation);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  const handleStartDateChange = (Date) => {
    setStartDate(Date);
    setEndDate("");
  };

  const handleEndDateChange = (Date) => {
    setEndDate(Date);
  };

  const handleTypeChange = (selected) => {
    setType(selected);
  };

  const handleApply = () => {
    let payload = {
      limit: 10,
      skip: 0,
      fromDate: startDate,
      toDate: endDate,
      transactionType: type.value,
    };
    setCurrentIndex(0);
    dispatch(
      getTransactions(payload, (response) => {
        setData(response?.data?.transation);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  const fetchRecords = ({ selected }) => {
    let payload = {
      limit: 10,
      skip: selected,
      fromDate: startDate,
      toDate: endDate,
      transactionType: type.value,
    };
    setCurrentIndex(selected);
    dispatch(
      getTransactions(payload, (response) => {
        setData(response?.data?.transation);
        setTotalCount(response?.data?.totalCount);
      })
    );
  };

  useEffect(() => {
    let payload = {
      limit: 10,
      skip: 0,
      fromDate: startDate,
      toDate: endDate,
      transactionType: type?.value || "",
    };
    dispatch(
      getTransactions(payload, (response) => {
        setData(response?.data?.transation);
        setTotalCount(response?.data?.totalCount);
      })
    );
  }, []);

  const showActiveStatus = (value) => {
    switch (value) {
      case TRANSACTION_STATUS.APPROVED:
        return t("approved");

      case TRANSACTION_STATUS.REJECTED:
        return t("rejected");

      case TRANSACTION_STATUS.PENDING:
        return t("pending");

      case TRANSACTION_STATUS.CANCELLED:
        return t("canceled");
    }
  };

  const showActiveStatusClass = (value) => {
    switch (value) {
      case TRANSACTION_STATUS.APPROVED:
        return "success";

      case TRANSACTION_STATUS.REJECTED:
        return "info";

      case TRANSACTION_STATUS.PENDING:
        return "warning";

      case TRANSACTION_STATUS.CANCELLED:
        return "danger";
    }
  };

  const handleCancel = (value) => {
    dispatch(
      cancelTransaction({ transactionId: value }, (response) => {
        if (enqueueSnackbar) {
          enqueueSnackbar(t(response.msg), {
            variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        if (response.statusCode === STATUS_CODES.SUCCESS) {
          let payload = {
            limit: 10,
            skip: 0,
            fromDate: startDate,
            toDate: endDate,
            transactionType: type?.value || "",
          };
          dispatch(
            getTransactions(payload, (response) => {
              setData(response?.data?.transation);
              setTotalCount(response?.data?.totalCount);
            })
          );
        }
      })
    );
  };

  return (
    <div className="w-100 form_front pt-4">
      <div className="W-100 form_card bg-white text-start text-dark p-4">
        <div className="row align-items-end">
        <div className="col-md-6 col-lg-3">
            <div className="form-group">
              <label className="mb-2">{t("start-date")}</label>
              <div className="position-relative">
                <ReactDatePicker
                  selected={startDate}
                  dateFormat={"dd/MM/yyyy"}
                  onChange={handleStartDateChange}
                  placeholderText={"dd/mm/yyyy"}
                  className="form-control"
                // showFullMonthYearPicker={true}
                />
                <em className="form_icon"><img src={IMAGES.calendar} height="13" width="13" alt="" /></em>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="form-group">
              <label className="mb-2">{t("end-date")}</label>
              <div className="position-relative">
                <ReactDatePicker
                  selected={endDate}
                  dateFormat={"dd/MM/yyyy"}
                  onChange={handleEndDateChange}
                  placeholderText={"dd/mm/yyyy"}
                  minDate={startDate}
                  maxDate={new Date()}
                  className="form-control"
                // showFullMonthYearPicker={true}
                />
                <em className="form_icon"><img src={IMAGES.calendar} height="13px" width="13px" alt="" /></em>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="form-group">
              <label className="mb-2">{t("type-of-trans")}</label>
              <Dropdown
                className={"me-2"}
                id="dropdown"
                value={type}
                name="Account"
                placeholder="Choose type"
                options={TRANSACTION_TYPE_OPTIONS}
                onChange={handleTypeChange}
                chooseLabel={t("choose-bank")}
              />
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="form-group d-flex">
              <Button className="btn btn-md btn-primary" type="submit" onClick={handleApply}>
                {t("apply")}
              </Button>
              <button className="btn btn-md btn-transparent ms-2" onClick={handleReset}> {t("reset")}</button>
            </div>
          </div>
        </div>

        <>
          {data?.length ? (
            <>
            <div className="table-responsive">
              <Table className="table text-dark" headings={headings}>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{indexHistory(currentIndex, 10) + index}</td>
                      <td>{moment(item.createdAt).format("DD MMM YYYY HH:MM A")}</td>
                      <td>{item?.transactionType == 1 ?  t("deposit") :  t("withdraw")}</td>
                      <td>{item?.accountName}</td>
                      <td>{item?.accountNumber}</td>
                      <td>{item?.amount ? item?.amount?.toString()?.split(/(?=(?:\d{3})+(?:\.|$))/g)?.join(",") : 0}</td>
                      <td>
                        <span className={`badge badge-${showActiveStatusClass(item.transactionStatus)}`}  > {showActiveStatus(item.transactionStatus)} </span>
                      </td>
                      <td width="200">
                        {item?.transactionStatus == TRANSACTION_STATUS.PENDING ?
                        <Button className="btn btn-sm btn-danger" type="submit" onClick={() => handleCancel(item._id)}>
                          {t("cancel")}
                        </Button> :
                        ""
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

              <ReactPaginate
                previousLabel="<<"
                nextLabel=">>"
                breakLabel="..."
                pageCount={Math.ceil(totalCount / 10)}
                marginPagesDisplayed={3}
                onPageChange={fetchRecords}
                containerClassName="pagination justify-content-center mt-4"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLinkClassName="page-link"
                breakClassName="page-item"
                activeClassName="active"
              />
            </>
          ) : (
            <div className="p-5 d-flex flex-column justify-content-center text-center no-result">
              <em><img src={IMAGES.empty} alt="" width="44" /></em>
              <span className="h5 mt-3">{t("no-trans")}</span>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default LastTransactions;
