import React, { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import Button from "../Button";
import { useDispatch } from "react-redux";
import { forgotPassword} from "Redux/Actions/Auth";
import { useSnackbar } from "notistack";
import { STATUS_CODES } from "Services/Api/Constants";
import InputField from "../InputField";
import { useTranslation } from "react-i18next";
import NewPassword from "./NewPassword";
import VerifyForgotOtp from "./VerifyForgotOtp";

const ForgotModal = ({ open = false, setOpen = () => {}, toggleLoginModal = () => {} }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [active,setActive] = useState(1);

  const handleClick = () => {
    if (username == "") {
      setError(t("required"));
      return;
    } else if (username.length < 6) {
      setError(t("forgot-val"));
      return;
    }
    dispatch(
      forgotPassword({ userName : username }, (response) => {
        if (enqueueSnackbar) {
          enqueueSnackbar(t(response.msg), {
            variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        if(response.statusCode === STATUS_CODES.SUCCESS){
          setActive(2);
        }
      })
    );
  };

  const handleChange = (e) => {
    setUsername(e.target.value);
    if (e.target.value == "") {
      setError(t("required"));
    } else if (e.target.value.length < 6) {
      setError(t("forgot-val"));
    }else{
      setError("");
    }
  };

  useEffect(()=>{
    return ()=>{
      setError("");
      setActive(1);
    }
  },[])

  return (
    <CustomModal isOpen={open} title={t("forgot-password")} handleToggle={() => setOpen(!open)}>
      <>
        <div className="text-center">
          <div className="row">
            {active == 1 ?
            <>
          {/* <div className="text-dark ps-1 mb-2">
          {t("forgot-text")}
        </div> */}
            <div className="col-md-12">
              <div className="form-group">
                <div className="form-floating">
                  <InputField
                    className="form-control me-1"
                    label={t("username-email")}
                    name={"userName"}
                    type={"text"}
                    onChange={handleChange}
                  />
                  {error ? <span className="error d-block">{error}</span> : ""}
                </div>
              </div>
            </div>
            <div className="col-md-12 justify-content-center">
              <Button className="btn btn-md btn-primary me-sm-3 me-2 mb-sm-0" type="submit" onClick={handleClick}>
                {t("send")}
              </Button>
            </div>
            </>
            : active == 2 ?
            <div className="col-md-12 justify-content-center">
              <VerifyForgotOtp userName={username} setActive={setActive}/></div> :
             <NewPassword  setOpen={setOpen}/> }
            <div className="col-md-12 justify-content-center mt-3 cursor-ptr">
                    <span
                      className="text-dark"
                      onClick={() => {
                        setOpen(false);
                        toggleLoginModal(true);
                      }}
                    >
                      {t("login")}
                   </span>
            </div>
          </div>
        </div>
      </>
    </CustomModal>
  );
};

export default ForgotModal;
