import React from 'react'
import CustomModal from '../CustomModal'
import Button from '../Button';
import { useTranslation } from 'react-i18next';

const Popup = ({open = false, handleToggle = () =>{},message = "" }) => {
  const {t} = useTranslation();
  return (
    <CustomModal isOpen={open} title={t("success")} handleToggle={handleToggle}>
     <div className="text-black">
          <div className="row">
        <div className='col-md-12'>
        {message}
        </div>
        <div className='col-md-12 text-md-end common_btn'>
        <Button className="btn btn-md btn-primary" onClick={handleToggle}>
            {t("okay")}
        </Button>
        </div>
     </div>
     </div>
    </CustomModal>
  )
}

export default Popup