import { LOGIN, SAVE_USER_REGISTER_DETAILS, UPDATE_REDUCER_AUTH_TOKEN } from "../Actions/Auth";
import { ACTION_STATES } from "../Actions/ActionStates";

const initialState = {
  token: "",
  registerDetails : {}
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_REDUCER_AUTH_TOKEN : {
      return {
        ...state,
        token: action.token,
      };
    }

    case SAVE_USER_REGISTER_DETAILS : {
      return {
        ...state,
        registerDetails: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
