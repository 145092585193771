import { Button } from "Components";
import { vertifyOTP } from "Redux/Actions/Auth";
import { STATUS_CODES } from "Services/Api/Constants";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
const VertifyOTPScreen = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const userDetails = useSelector((state) => state.auth.registerDetails);

  const history = useHistory();
  const [otpValue, setOtpValue] = useState("");
  const [error, setError] = useState("");
  const handleClick = () => {
    if (!otpValue) {
      setError(t("pls-enter-otp"));
      return;
    }
    dispatch(
      vertifyOTP({ otp: otpValue, userName: userDetails?.email || "" }, (response) => {
        if (enqueueSnackbar) {
          enqueueSnackbar(t(response.msg), {
            variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
            autoHideDuration: 3000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          });
        }
        if (response.statusCode === STATUS_CODES.SUCCESS) {
          history.push(ROUTES.HOME);
        }
      })
    );
  };
  return (
    <div className="container py-md-5 py-3">
      <div className="common_screen">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="otpBlock text-center">
              <h3 className="h3">{t("pls-enter-otp")}</h3>
              <div className="form-field form-group">
                <input
                  className="form-control"
                  label={t("enter-otp")}
                  placeholder="Enter Otp"
                  value={otpValue}
                  onChange={(e) => setOtpValue(e.target.value)}
                />
                {error ? <span className="error d-block">{error}</span> : ""}
              </div>
              <Button className="btn btn-lg btn-primary" onClick={handleClick}>
                {t("verify-otp")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VertifyOTPScreen;
