import { Button, DeletePopup, Table } from "Components";
import { indexHistory } from "Shared/Constants";
import React, { useEffect, useState } from "react";
import AddAccount from "./AddAccount";
import { useDispatch } from "react-redux";
import { deleteBankAccount, getAccountTypes, getBankAccounts } from "Redux/Actions/user";
import ReactPaginate from "react-paginate";
import { useSnackbar } from "notistack";
import { STATUS_CODES } from "Services/Api/Constants";
import { useTranslation } from "react-i18next";

const AccountInfo = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const headings = [t("no"), t("account"), t("account-brand"), t("account-name"), t("account-number"), t("action")];
  const [dataRecords, setDataRecords] = useState([]);
  const [totalCount, setTotalCount] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [edit, setEdit] = useState(false);
  const [editDetails, setEditDetails] = useState({});
  const [accountTypes, setAccountTypes] = useState([]);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteDetails, setDeleteDetails] = useState("");


  const fetchRecords = ({ selected }) => {
    let payload = {
      limit: 10,
      skip: selected,
    };
    setCurrentIndex(selected);
    dispatch(
      getBankAccounts(payload, (response) => {
        setDataRecords(response.data.bankAccount);
        setTotalCount(response.data.totalCount);
      })
    );
  };

  useEffect(() => {
    let payload = {
      limit: 10,
      skip: 0,
    };
    dispatch(
      getBankAccounts(payload, (response) => {
        setDataRecords(response.data.bankAccount);
        setTotalCount(response.data.totalCount);
      })
    );

    dispatch(getAccountTypes((response) => {
      if (response.statusCode == STATUS_CODES.SUCCESS) {
        setAccountTypes(response?.data?.accountType?.accountType);
      }
    }));


  }, []);

  const handleEdit = (data) => {
    setEdit(true);
    setOpenModal(true);
    setEditDetails(data);
  }

  const handleDeleteSubmit = () => {
    dispatch(deleteBankAccount({ bankId: deleteDetails }, (response) => {
      if (enqueueSnackbar) {
        enqueueSnackbar(t(response.msg), {
          variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
          autoHideDuration: 3000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      }
      if (response.statusCode === STATUS_CODES.SUCCESS) {
        setOpenDeleteModal(false);
        setDeleteDetails("");

        let payload = {
          limit: 10,
          skip: 0,
        };
        dispatch(
          getBankAccounts(payload, (response) => {
            setDataRecords(response.data.bankAccount);
            setTotalCount(response.data.totalCount);
            setCurrentIndex(0);
          })
        );
      }
    }))
  }


  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    setDeleteDetails(id);
  }

  return (
    <div className="w-100 pt-4 form_front">
      <div className="w-100 form_card bg-white text-start p-4">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="h4 page_title mb-3">{t("my-account")}</h1>
          <Button className="btn btn-md btn-primary mb-3" onClick={() => setOpenModal(true)}>
            {t("add-account")}
          </Button>
        </div>
        <div className="table-responsive">
          <Table className="w-100 table text-dark" headings={headings}>
            <tbody>
              {dataRecords.map((item, index) => (
                <tr key={index}>
                  <td>{indexHistory(currentIndex, 10) + index}</td>
                  <td>{item.accountTypeName}</td>
                  <td>{item.accountBrandName}</td>
                  <td>{item.accountName}</td>
                  <td>{item.accountNumber}</td>
                  <td className="d-flex" width="140">
                    <Button className="m-1 btn btn-sm btn-secondary" onClick={() => handleEdit(item)}>
                      {t("edit")}
                    </Button>
                    <Button className="m-1 btn btn-sm btn-red" onClick={() => handleDelete(item?._id)}>
                      {t("delete")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {dataRecords?.length ? (
            <ReactPaginate
              previousLabel="<<"
              nextLabel=">>"
              breakLabel="..."
              pageCount={Math.ceil(totalCount / 10)}
              marginPagesDisplayed={3}
              onPageChange={fetchRecords}
              containerClassName="pagination justify-content-center mt-4"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              breakClassName="page-item"
              activeClassName="active"
            />
          ) : (
            <span></span>
          )}
        </div>
      </div>
      {openDeleteModal && <DeletePopup open={openDeleteModal} toggle={() => { setOpenDeleteModal(!openDeleteModal); }} title={t("delete")} message={t("delete-msg")} handleSubmit={handleDeleteSubmit} />}

      {openModal && <AddAccount open={openModal} toggle={() => { setOpenModal(!openModal); setEdit(false); }} edit={edit} setEdit={setEdit} editdetails={editDetails} setEditDetails={setEditDetails} fetchRecords={fetchRecords} accountTypes={accountTypes} />}
    </div>
  );
};

export default AccountInfo;
