import { Button, InputFieldFormik } from "Components";
import DropDown from "Components/Atoms/Dropdown";
import { register, saveUserRegisterDetails } from "Redux/Actions/Auth";
import { STATUS_CODES } from "Services/Api/Constants";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import ClientCaptcha from "react-client-captcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { IMAGES } from "Shared/Images";
import "./style.scss";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
import { getAccountBrands, getAccountTypes } from "Redux/Actions/user";
import parseUrl from "parse-url";
import useShowResponse from "Shared/ShowResponse";

const Register = () => {
  const { t } = useTranslation();
  const validate = Yup.object({
    userName: Yup.string().trim().min(3, t("username-short")).max(60, t("max-60")).required(t("required")),
    password: Yup.string()
      .required(t("required"))
      .matches(/^(?=.*[a-zA-Z!@#\$%\^&\*])(?=.*[0-9]).{6,}$/, t("password-val")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("password-match"))
      .required(t("required")),
    accountName: Yup.string()
      .trim()
      .max(60, t("max-60"))
      .matches(/^[a-zA-Z\s]*$/, t("name-val"))
      .required(t("required")),
    accountNumber: Yup.string()
      .trim()
      .max(30, t("acc-no-max"))
      .matches(/^[0-9]+$/i, t("acc-no-val"))
      .required(t("required")),
    referral: Yup.string(),
  });

  const history = useHistory();
  const dispatch = useDispatch();
  const showResponse = useShowResponse();

  const [bankOptions, setBankOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);
  const [accountBrand, setAccountBrand] = useState("");
  const [referral, setReferral] = useState("");
  const [bank, setBank] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [error, setError] = useState({
    bank: "",
    currency: "",
    validation: "",
    phoneNumber: "",
    accountBrand: "",
  });

  const handleSubmit = (value) => {
    // if (!bank || !currency || !phoneNumber || value.validationCode !== code) {
    if (!bank || value.validationCode !== code || !accountBrand.value) {
      setError((prev) => ({
        ...prev,
        bank: bank ? "" : t("bank-req"),
        // phoneNumber: phoneNumber.length < 10 ? t("phone-val") : "",
        validation: value.validationCode == code ? "" : t("code-match"),
        accountBrand: accountBrand.value ? "" : t("brand-val"),
      }));
      return;
    }

    let payload = { ...value, accountBrandId: accountBrand.value, mobile: phoneNumber };
    delete payload.confirmPassword;
    delete payload.validationCode;
    dispatch(
      register(payload, (response) => {
        dispatch(saveUserRegisterDetails(payload));
        showResponse({
          key: response.msg,
          variant: response.statusCode === STATUS_CODES.SUCCESS ? "success" : "error",
          vertical: 'top',
          horizontal: 'right'
        });

        if (response.statusCode === STATUS_CODES.SUCCESS) {
          // dispatch(openLoginModal(true));
          history.push(ROUTES.THANKYOU);
        }
      })
    );
  };

  const fetchAccountBrands = (id) => {
    dispatch(
      getAccountBrands({ accountTypeId: id }, (response) => {
        if (response.statusCode == STATUS_CODES.SUCCESS) {
          let options = response?.data?.accountBrand?.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          });
          setBrandOptions(options);
          if (options?.length == 1) {
            setAccountBrand(options[0]);
            setError((prev) => ({ ...prev, accountBrand: "" }));
          }
        }
      })
    );
  };

  const setCookie = (name, value, minutes) => {
    const date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value};${expires};path=/`;
  };

  const getQueryStringValue = (key) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(key);
  };

  useEffect(() => {
    dispatch(
      getAccountTypes((response) => {
        if (response.statusCode == STATUS_CODES.SUCCESS) {
          let options = response?.data?.accountType?.accountType?.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          });
          setBankOptions(options);
        }
      })
    );

    let reff = parseUrl(window.location.href);
    setReferral(reff?.query?.ref || "");

    const adsBemobCid = getQueryStringValue('cid');
    if (adsBemobCid) {
      // Set ads for bemob
      setCookie('ads_bemob_cid', adsBemobCid, 60);
    }
  }, []);

  const formatAccountNumber = (value) => {
    console.log(value)
    const cleaned = value.replace(/\D+/g, "");
    const formatted = cleaned.match(/.{1,4}/g)?.join("-") || "";

    return formatted;
  };

  return (
    <div className="d-flex align-items-center justify-content-center w-100 form_front min-vh-100 register-secn">
      <div className="container py-md-5 pt-4">
        <div className="form_card bg-white text-start p-4">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={validate}
            initialValues={{
              userName: "",
              email: "",
              password: "",
              confirmPassword: "",
              accountName: "",
              accountNumber: "",
              referral: referral,
              validationCode: "",
            }}
            enableReinitialize
          >
            {(props) => (
              <>
                <Form
                  className="form_pad"
                  onChange={(e) => {
                    if (e?.target?.name == "validationCode") {
                      setError((prev) => ({
                        ...prev,
                        validation: e.target.value == code ? "" : t("code-match"),
                      }));
                    }
                    if (e?.target?.name == "userName") {
                      props.setFieldValue("userName", e?.target?.value?.trim()?.replaceAll(" ", ""));
                    }
                  }}
                  autoComplete="new-password"
                >
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="mb-4">
                        <h1 className="h4 page_title">{t("game-information")}</h1>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <InputFieldFormik
                              name={"userName"}
                              placeholder="Type here"
                              label={t("username")}
                              type={"text"}
                              required={true}
                              autoComplete="new-password"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group password-view">
                                <InputFieldFormik
                                  name={"password"}
                                  label={t("password")}
                                  type={showPassword.password ? "text" : "password"}
                                  required={true}
                                  autoComplete="new-password"
                                />
                                <div>
                                  <ul>
                                    <li><small className="text-black fst-italic">* Minimum 6 Character</small ></li>
                                    <li><small className="text-black fst-italic">* Contains Alphabet and number</small ></li>
                                    <li><small className="text-black fst-italic">* Can only use any of these special character !@#$%^&*</small ></li>
                                  </ul>
                                </div>
                                <span
                                  className="pwd-view-img"
                                  onClick={(prev) => setShowPassword({ ...prev, password: !showPassword.password })}
                                >
                                  <img
                                    src={showPassword.password ? IMAGES.eyeOpen : IMAGES.eyeClose}
                                    className="img-fluid"
                                    alt="imgOpen"
                                  />
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group password-view">
                                <InputFieldFormik
                                  name={"confirmPassword"}
                                  label={t("confirm-password")}
                                  type={showPassword.confirmPassword ? "text" : "password"}
                                  required={true}
                                  autoComplete="new-password"
                                />
                                <span
                                  className="pwd-view-img"
                                  onClick={(prev) =>
                                    setShowPassword({
                                      ...prev,
                                      confirmPassword: !showPassword.confirmPassword,
                                    })
                                  }
                                >
                                  <img
                                    src={showPassword.confirmPassword ? IMAGES.eyeOpen : IMAGES.eyeClose}
                                    className="img-fluid"
                                    alt="imgOpen"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-md-5 ">
                      <div className="mb-4">
                        <h1 className="h4 page_title">{t("personal-information")}</h1>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group phone_select_drop">
                            <label htmlFor="inputAddress" className="form-label">
                              {t("phone-number")}
                              {/* <sup className="req_txt">*</sup> */}
                            </label>
                            <PhoneInput
                              enableAreaCodes={false}
                              enableAreaCodeStretch={false}
                              country={"id"}
                              value={phoneNumber}
                              onChange={(phone) => {
                                setError((prev) => ({
                                  ...prev,
                                  phoneNumber: phone.length < 10 ? t("phone-val") : "",
                                }));
                                setPhoneNumber(`+${phone}`);
                              }}
                            />
                            {error?.phoneNumber ? (
                              <span className="error d-block text-start">{error.phoneNumber}</span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <InputFieldFormik
                              name={"email"}
                              label={t("email")}
                              type={"email"}
                              //   required={true}
                              autoComplete="new-password"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mt-md-4 mt-3">
                      <div className="mb-4">
                        <h1 className="h4 page_title">{t("account-information")}</h1>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group sky_bg_group">
                                <label htmlFor="inputPassword4" className="form-label">
                                  {t("account")} <sup className="req_txt">*</sup>
                                </label>
                                <DropDown
                                  className={"me-2"}
                                  id="dropdown"
                                  value={bank}
                                  name="Account"
                                  placeholder={t("choose-account")}
                                  options={bankOptions}
                                  onChange={(value) => {
                                    setError((prev) => ({ ...prev, bank: "" }));
                                    setBank(value);
                                    fetchAccountBrands(value.value);
                                    setAccountBrand("");
                                  }}
                                />
                                {error?.bank ? <span className="error d-block">{error.bank}</span> : ""}
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group sky_bg_group">
                                <label htmlFor="inputPassword4" className="form-label">
                                  {t("account-brand")} <sup className="req_txt">*</sup>
                                </label>
                                <DropDown
                                  className={"me-2"}
                                  id="dropdown"
                                  value={accountBrand}
                                  name="Account"
                                  placeholder={t("choose-brand")}
                                  options={brandOptions}
                                  onChange={(value) => {
                                    setError((prev) => ({ ...prev, accountBrand: "" }));
                                    setAccountBrand(value);
                                    // props.setFieldValue()
                                  }}
                                />
                                {error?.accountBrand ? <span className="error d-block">{error.accountBrand}</span> : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <InputFieldFormik
                              name={"accountName"}
                              label={t("account-name")}
                              type={"text"}
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <InputFieldFormik
                              name={"accountNumber"}
                              id="accountNumber"
                              label={t("account-number")}
                              type={"text"}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mt-md-4 mt-3">
                      <div className="mb-4">
                        <h1 className="h4 page_title">{t("other-information")}</h1>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <InputFieldFormik
                              name={"referral"}
                              label={t("referral-id")}
                              type={"text"}
                              disabled={referral ? true : false}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <div className="d-flex justify-content-between align-items-center flex-wrap flex-md-nowrap validation_row">
                              <div className="valdiation_box">
                                <InputFieldFormik
                                  name={"validationCode"}
                                  label={t("validation-code")}
                                  type={"text"}
                                  error={error?.validation?.length ? true : false}
                                  required={true}
                                  autoComplete="new-password"
                                />
                              </div>
                              <div className="captcha-box">
                                <ClientCaptcha captchaCode={(code) => setCode(code)} required={true} chars="abcdefghijklmnopqrstuvwxyz0123456789"/>
                              </div>
                            </div>
                            {error?.validation ? <span className="error d-block">{error.validation}</span> : ""}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-check ps-0 d-flex">
                        <label className="form-check-label" htmlFor="flexCheckDefault"></label>
                        <span className="text-black ps-1">
                          {t("check-text-1")}{" "}
                          <a onClick={() => window.open(`${window.location.origin}${ROUTES.TERMS_CONDITIONS}`, "_blank")} className="current">
                            {t("check-terms")}
                          </a>
                          &nbsp;
                          {t("check-text-2")}
                        </span>
                      </div>
                    </div>
                    <div className="col-12 text-center text-md-end common_btn mt-md-5">
                      <Button className="btn btn-lg btn-primary" type="submit">
                        {t("register")}
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Register;
