import { SET_GAME_LINK } from "Redux/Actions/game";

const initialState = {
    link : "",
  };
  const GameReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_GAME_LINK : {
        return {
          ...state,
            link : action.link
        };
      }
  
      default: {
        return state;
      }
    }
  };
  
  export default GameReducer;
  