import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Routes/constants";
import { useDispatch } from 'react-redux';
import { fetchFooterPagesCmsContent } from "Redux/Actions/CMS";
import { CMS_SECTION_TYPE } from "Shared/Constants";
import "./style.scss";

const ThankYou = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [count, setCount] = useState(5);
  const intervalId = useRef(null);
  const [content,setContent] = useState("");

  const clear = (id) => {
    clearInterval(id);
  };

  useEffect(() => {   
    dispatch(fetchFooterPagesCmsContent({ type : CMS_SECTION_TYPE.THANK_YOU },(response)=>{
       setContent(response?.data || "");
    }))

    intervalId.current = setInterval(() => {
      setCount((prev) => --prev);
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, [dispatch]);

  const getCookie = (name) => {
    const nameEQ = name + "=";
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  useEffect(() => {
    if (count === 0) {
      clear(intervalId.current);
      history.push(ROUTES.HOME);
    }

    // Add bemob
    const cid = getCookie('ads_bemob_cid');
    if (cid) {
      fetch(`https://f46oj.bemobtrcks.com/postback?cid=${cid}`)
      .then(() => {
        deleteCookie('ads_bemob_cid');
      });
    }
  }, [count, history]);

  return (
    <div className="container py-md-5 py-3">
      <div className="common_screen">
        <div dangerouslySetInnerHTML={{__html : content?.description || ""}}></div>
      </div>
    </div>
  );
};

export default ThankYou;
