import React, { useEffect, useState } from "react";
import { IMAGES } from "Shared/Images";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { ROUTES } from "Routes/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getFooterBanks, getSocialLinks } from "Redux/Actions/common";
import { CONTACT_TYPE } from "Shared/Constants";


const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const footerDescription = useSelector((state) => state?.cms?.footerText || "");
  const whiteLabelData = useSelector(state => state?.common?.whiteLabelData || {});

  const [links, setLinks] = useState({});
  const [banks, setBanks] = useState([]);

  useEffect(() => {
    dispatch(
      getSocialLinks((response) => {
        let data = response.contacts;
        let link = data.reduce((prev, curr) => {
          let res = { ...prev };
          switch (curr.contactType) {
            case CONTACT_TYPE.FACEBOOK:
              res.facebook = curr.contactDetails;
              break;
            case CONTACT_TYPE.INSTAGRAM:
              res.instagram = curr.contactDetails;
              break;
            case CONTACT_TYPE.PHONE:
              res.phone = curr.contactDetails;
              break;
            case CONTACT_TYPE.MESSAGE:
              res.message = curr.contactDetails;
              break;
            case CONTACT_TYPE.LINE:
              res.line = curr.contactDetails;
              break;
            case CONTACT_TYPE.WHATSAPP:
              res.whatsapp = curr.contactDetails;
              break;
            case CONTACT_TYPE.WE_CHAT:
              res.wechat = curr.contactDetails;
              break;
            case CONTACT_TYPE.TELEGRAM:
              res.telegram = curr.contactDetails;
              break;
            case CONTACT_TYPE.TWITTER:
              res.twitter = curr.contactDetails;
              break;
            case CONTACT_TYPE.TIKTOK:
              res.tiktok = curr.contactDetails;
              break;
            case CONTACT_TYPE.EMAIL:
              res.email = curr.contactDetails;
              break;

            default:
          }
          return res;
        }, {});
        setLinks(link);
      })
    );

    dispatch(
      getFooterBanks((response) => {
        setBanks(response);
      })
    );
  }, []);

  return (
    <footer className="pt-4 text-muted footer">
      <div className="container">
        <div className="d-flex justify-content-center align-items-center footer_pages_links pb-md-4 py-3">
          <ul className="list-unstyled p-0 m-0 d-flex justify-content-center flex-wrap align-items-center ">
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.ABOUTUS)}>{t("about-us")}</a>
            </li>
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.RESPONSIBLE_GAMING)}>{t("responsible-gaming")}</a>
            </li>
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.HELPCENTER)}>{t("help-center")}</a>
            </li>
            <li className="link_item px-4">
              <a onClick={() => history.push(ROUTES.TERMS_CONDITIONS)}>{t("terms-conditions")}</a>
            </li>
          </ul>
        </div>
        <div className="justify-content-start flex-wrap align-items-center mt-md-4 mt-3  partners_secn">
          {banks?.length
            ? banks.map((item, index) => (
                <div
                  key={index}
                  className="bank common_partner d-flex align-items-md-center align-items-start flex-wrap"
                >
                  <div className="me-3 partner_name">
                    <span>{item._id} </span>
                  </div>
                  <div className="partner_logo">
                    {item?.isActaccountBrand?.length
                      ? item?.isActaccountBrand.map((itm, indx) => (
                          <a key={indx} className="link-secondary">
                            <img className="img-fluid" src={itm?.brandImage} alt="Bank" width="100px" />
                          </a>
                        ))
                      : ""}
                  </div>
                </div>
              ))
            : ""}
        </div>

        <div className="main_footer">
          <div className="mt-4">
            <div className="row px-0 px-lg-4">
              <div className="col-xl-5 col-lg-4 col-md-12 col-sm-6 col-12 mb-4">
                <div className="footer_item pe-xl-5">
                  <h6 className="h6 text-uppercase fw-bold mb-4">
                    <img src={whiteLabelData?.brandImage || ""} alt="Logo" className="img-fluid" width="200" />
                  </h6>
                  <div dangerouslySetInnerHTML={ {__html: footerDescription}}></div>
                </div>
              </div>

              <div className="col-xl-5 col-lg-5 col-md-7 col-sm-6 col-12 mb-4">
                <div className="footer_item">
                  <h6 className="h6 text-uppercase fw-bold mb-4">{t("contact-us")}</h6>
                  <div className="footer_list">
                    <ul>
                      {links?.whatsapp ? (
                        <li>
                          <a href={`https://wa.me/${links?.whatsapp}`} target="_blank">
                            <em>
                              <img src={IMAGES.whatsapp} alt="Whatsapp" width="34" />
                            </em>{" "}
                            Whatsapp
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.telegram ? (
                        <li>
                          <a href={`https://t.me/${links?.telegram}`} target="_blank">
                            <em>
                              <img className="img-fluid" src={IMAGES.telegram} width="34" alt="Telegram" />
                            </em>{" "}
                            Telegram
                          </a>
                        </li>
                      ) : (
                        ""
                      )}

                      {links?.wechat ? (
                        <li>
                          <a href={`weixin://dl/chat?${links?.wechat}`} >
                            <em>
                              <img className="img-fluid" src={IMAGES.wechat} width="34" alt="Wechat" />
                            </em>{" "}
                            WeChat
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.skype ? (
                        <li>
                          <a href={`skype:${links?.skype}?chat`} >
                            <em>
                              <img className="img-fluid" src={IMAGES.skype} width="34" alt="Skype" />
                            </em>{" "}
                            Skype
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.phone ? (
                        <li>
                          <a href={`tel:${links?.phone}`}>
                            <em>
                              <img className="img-fluid" src={IMAGES.phone} alt="Phone" width="34" />
                            </em>{" "}
                            <span>{links.phone}</span>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.message ? (
                        <li>
                          <a href={`sms:+${links?.message}`}>
                            <em>
                              <img className="img-fluid" src={IMAGES.messages} alt="Phone" width="34" />
                            </em>{" "}
                            <span>{links.message}</span>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.email ? (
                        <li>
                          <a href={`mailto:${links.email}`} >
                            <em>
                              <img className="img-fluid" src={IMAGES.email} alt="Email" width="34" />
                            </em>{" "}
                            <span>{links.email}</span>
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-2 col-lg-3 col-md-5 col-sm-6 col-12 mb-4">
                <div className="footer_item">
                  <h6 className="h6 text-uppercase fw-bold mb-4">{t("social-media")}</h6>
                  <div className="footer_list">
                    <ul className="d-flex flex-column">
                      {links?.instagram ? (
                        <li>
                          <a href={`https://www.instagram.com/${links?.instagram}`} target="_blank">
                            <em>
                              <img src={IMAGES.insta} alt="Instagram" width="34" />
                            </em>
                            Instagram
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.facebook ? (
                        <li>
                          <a href={`https://www.facebook.com/${links?.facebook}`} target="_blank">
                            <em>
                              <img src={IMAGES.facebook} alt="Facebook" width="34" />
                            </em>
                            Facebook
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.line ? (
                        <li>
                          <a href={`https://line.me/R/oaMessage/${links?.line}`} target="_blank">
                            <em>
                              <img src={IMAGES.line} alt="Facebook" width="34" />
                            </em>
                            Line
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.twitter ? (
                        <li>
                          <a href={`https://twitter.com/${links?.twitter}`} target="_blank">
                            <em>
                              <img src={IMAGES.twitter} alt="Facebook" width="34" />
                            </em>
                            Twitter
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                      {links?.tiktok ? (
                        <li>
                          <a href={`https://www.tiktok.com/@${links?.tiktok}`} target="_blank">
                            <em>
                              <img src={IMAGES.tiktok} alt="Facebook" width="34" />
                            </em>
                            TikTok
                          </a>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-center py-3 copyright_text">
          <span>© {whiteLabelData?.brandName || ""} ALL RIGHTS RESERVED</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
