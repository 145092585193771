import React from 'react'
import './style.scss';

const Table = ({ className = "", headings = [], children }) => {
  return (
    <table className={className} >
      <thead>
        <tr>
          {headings.map((item,index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      {children}
    </table>
  )
}

export default Table