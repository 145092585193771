import { IMAGES } from "Shared/Images";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const GAMEPROVIDERS = [
  IMAGES.netent,
  IMAGES.playgo,
  IMAGES.yggdrasil,
  IMAGES.pramatic,
  IMAGES.elk,
  IMAGES.microgaming,
  IMAGES.evolution,
  IMAGES.redtiger,
  IMAGES.isoftbet,
  IMAGES.quickspam,
];

const GameProviders = ({ data }) => {
  const { t } = useTranslation();
  const providers = useSelector((state) => state?.common?.providers || []);

  return (
    <div className="game_providers">
      <div className="container ">
        <div className="common_title">
          <h2 className="h2">{t("game-providers")}</h2>
        </div>

        <div className="provider_List py-3 pt-md-5">
          <div className="row gx-lg-5 align-items-center provider_List_row">
            {providers?.length
              ? providers?.map((item, index) => (
                <div key={index} className="col-4 col-lg-2 text-center">
                  <img src={item.image} className="img-fluid" alt="Providers" />
                </div>
              ))
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameProviders;
