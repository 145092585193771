import React from "react";

function InputField({ textAria = false, children,type,step,min, max,disabled,onChange,onBlur,rows,required = false,label,icon = "",error = "" ,...props} = {}) {

  return (
    <>
    <div className="form-floating">
        {/* {icon && (
          <span className="inputImage">
            <img alt="icon" src={icon} />
          </span>
        )} */}
        {textAria == false ? (
          <input
            min={min}
            max={max}
            type={type}
            step={step}
            placeholder="Type here"
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            className={`form-control
            ${error ? "is-invalid" : ""}`}
            id="floatingInput"
            autoComplete="off"
            {...props}
          />
        ) : (
          <textarea
            className={`form-control ${error ? "is-invalid" : ""}`}
            autoComplete="off"
            rows={rows}
            placeholder="Type here"
            {...props}
            id="floatingInput"
          >
            {children}
          </textarea>
        )}
      {label ? <label htmlFor="floatingInput">
        {label} {required ? <sup className="req_txt">*</sup> : ""}
      </label> : ""}
      {error ?
      <span className="error d-block">
       {error}
      </span> : "" }
      </div>
    </>
  );
}
export default InputField;
