import { SET_PROMOTION_DETAIL, SET_PROVIDERS, SET_WHITE_LABEL_DATA } from "Redux/Actions/common";

const initialState = {
  activePage : 1,
  promotionDetail : {},
  dynamicContent : {},
  providers : {},
  whiteLabelData : {}
};
const CommonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROMOTION_DETAIL : {
      return {
        ...state,
        promotionDetail : action.payload
      };
    }
    
    case SET_PROVIDERS : {
      return {
        ...state,
        providers : action.payload
      };
    }
    case SET_WHITE_LABEL_DATA : {
      return {
        ...state,
        whiteLabelData : action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default CommonReducer;
