import { Button } from "Components";
import { STRINGS } from "Shared/Constants";
import { IMAGES } from "Shared/Images";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ReferralLink = () => {
  const {t} = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userDetails = useSelector((state) => state.user.details);
  const [link, setLink] = useState(`${window.location.origin}/register??ref=${userDetails?.userName || ""}`);

  const copyLink = async () => {
    await navigator.clipboard.writeText(link);
    if (enqueueSnackbar) {
      enqueueSnackbar(t("copy-msg"), {
        variant: "success",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "top",
          horizontal: "center",
        },
      });
    }
  }
  
  useEffect(()=>{
   setLink(`${window.location.origin}/register?ref=${userDetails?.userName || ""}`);
  },[userDetails]);
  
  return (
    <div className="d-flex align-items-center justify-content-center register-secn py-md-5 pt-4">
      <div className="container">
        <div className="bg-white radius-15 text-start p-4">
          <div className="page_title mb-4">
            <h1 className="h4">{t("referral-link")}</h1>
          </div>
          <div className="m-auto d-flex align-items-center justify-content-center flex-wrap text-dark bg-grey text-center radius-15 col-md-6 p-4">
            <span className="me-3">{link}</span>
            <Button className="btn btn-md btn-primary" type="submit" onClick={copyLink}>
              {t("copy")} <em className="ms-2"><img width="12" src={IMAGES.copy} alt="" /></em>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralLink;
