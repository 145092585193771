import React from "react";
import { useField, ErrorMessage } from "formik";

function InputFieldFormik({ textAria = false, children,type,step,min, max,disabled,placeholder="Type here",onChange,rows,required = false,label,icon = "",error = false ,...props} = {}) {
  const [field, meta] = useField(props);

  return (
    <>
    <div className="form-floating">
        {/* {icon && (
          <span className="inputImage">
            <img alt="icon" src={icon} />
          </span>
        )} */}
        {textAria == false ? (
          <input
            min={min}
            max={max}
            type={type}
            step={step}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChange}
            className={`form-control
            ${error ? "is-invalid" : ""}
             ${ meta.touched && meta.error && "is-invalid"}`}
            autoComplete="off"
            {...field}
            {...props}
          />
        ) : (
          <textarea
            className={`form-control ${error ? "is-invalid" : ""} ${meta.touched && meta.error && "is-invalid"}`}
            autoComplete="off"
            rows={rows}
            placeholder="Type here"
            {...field}
            {...props}
          >
            {children}
          </textarea>
        )}
      {label ? <label htmlFor="floatingInput">
        {label} {required ? <sup className="req_txt">*</sup> : ""}
      </label> : ""}
      <span className="error d-block">
        <ErrorMessage name={field.name} />
      </span>
      </div>
    </>
  );
}
export default InputFieldFormik;
