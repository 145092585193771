import { io } from "socket.io-client";
import { SOCKET_URL } from "./Api/Constants";
export class SocketManager {
    socket = "";
    connected = false;
    async establishConnection(token, dispatchSocket) {
        this.socket = io(`${SOCKET_URL}?authorization=${token}`, {
            transports: ["websocket"],
        });
        this.socket.on("connect_error", (err) => {
            console.log("error", err);
        });

        this.socket.on("connect", () => {
            console.log("connection begin", this.socket);
            this.connected = true;
            dispatchSocket(this.socket)
        });

        this.socket.on("disconnect", function () {
            console.log("disconnected");
            this.connected = false;
            dispatchSocket(this.socket)
        });
    }

    disconnectConnection() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket.removeAllListeners();
        }
    }

    emitEvent(eventName, data, func = () => { }) {
        console.log(eventName, "testing!!", data)
        this.socket.emit(eventName, data, func);
    }
    onJoinEvent(eventName, val = () => { }) {
        this.socket.on(eventName, (res) => {
            val(res);
        });
    }
    onConnect(val = () => { }) {
        this.socket.on("connect", (socket) => {
            val(socket);
        });
    }
    onListenEvent(eventName, val = () => { }) {
        console.log("Listening to " + eventName)
        this.socket.on(eventName, (res) => {
            val(res);
        });
    }
    disconnectEvent(eventName) {
        this.socket.removeAllListeners(eventName);
    }
    removeListenerEvent() {
        this.socket.removeAllListeners();
    }
    closeSocket() {
        this.socket.close()
    }
}