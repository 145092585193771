import React from "react";
import CustomModal from "../CustomModal";
import Button from "../Button";
import { useTranslation } from "react-i18next";

const DeletePopup = ({ open = false, title = "", toggle = () => {}, message = "", handleSubmit = () => {} }) => {
  const { t } = useTranslation();
  return (
    <CustomModal isOpen={open} title={title} handleToggle={toggle}>
          <div className="text-black pb-3">{message}</div>
          <div className="d-flex text-center justify-content-center">
            <Button className="m-1 btn btn-md btn-primary" onClick={handleSubmit}>
              {t("delete")}
            </Button>
            <Button className="m-1 btn btn-md btn-red" onClick={toggle}>
              {t("cancel")}
            </Button>
          </div>
    </CustomModal>
  );
};

export default DeletePopup;
