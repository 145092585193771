import { setFooterText, setRunningText, setSiteTitle, setWelcomeCmsContent } from "Redux/Actions/CMS";
import { GET_FOOTER_PAGES_CMS_CONTENT, GET_FOOTER_TEXT_CONTENT, GET_RUNNING_TEXT_CONTENT, GET_SITE_SETTINGS_CMS_CONTENT, GET_WELCOME_CONTENT } from "Redux/Actions/constants";
import { startLoading, stopLoading } from "Redux/Actions/loader";
import { API_URLS, STATUS_CODES } from "Services/Api/Constants";
import { getRequestNoAuth } from "Shared/Axios";
import { all, put, takeLatest } from "redux-saga/effects";


function* fetchWelcomeCmsContent({ callback = () => { }}) {
    try {
      let apiUrl = `${API_URLS.POPUP_BANNER}`;
      const { data } = yield getRequestNoAuth({
        API: apiUrl,
      });
     if (data.statusCode == STATUS_CODES.SUCCESS) {
        callback(data?.data);
        yield put(setWelcomeCmsContent(data?.data?.popupBanners || {}));
      } else {
        // fail(data.msg);
      }
    } catch (error) {
      console.log(error,"error");
    } finally {
    }
}

function* fetchRunningText({ callback = () => { }}) {
    try {
      let apiUrl = `${API_URLS.RUNNING_TEXT}`;
      const { data } = yield getRequestNoAuth({
        API: apiUrl,
      });
     if (data.statusCode == STATUS_CODES.SUCCESS) {
        callback(data?.data);
        yield put(setRunningText(data?.data?.runningTexts || []));
      } else {
        // fail(data.msg);
      }
    } catch (error) {
      console.log(error,"error");
    } finally {
    }
}

function* fetchFooterText({ callback = () => { }}) {
    try {
      let apiUrl = `${API_URLS.FOOTER}`;
      const { data } = yield getRequestNoAuth({
        API: apiUrl,
      });
     if (data.statusCode == STATUS_CODES.SUCCESS) {
        callback(data?.data);
        yield put(setFooterText(data?.data?.text || ""));
      } else {
        // fail(data.msg);
      }
    } catch (error) {
      console.log(error,"error");
    } finally {
    }
}

function* fetchFooterPagesCmsContent({ payload,callback = () => { }}) {
    try {
      yield put(startLoading());
      let apiUrl = `${API_URLS.HOW_IT_WORKS}?type=${payload.type}`;
      const { data } = yield getRequestNoAuth({
        API: apiUrl,
      });
     if (data.statusCode == STATUS_CODES.SUCCESS) {
        callback(data);
        yield put(stopLoading());
      } else {
        // fail(data.msg);
      }
    } catch (error) {
      console.log(error,"error");
    } finally {
      yield put(stopLoading());
    }
}

function* fetchSiteSettingsCmsContent({ payload,callback = () => { }}) {
    try {
      yield put(startLoading());
      const { data } = yield getRequestNoAuth({
        API: API_URLS.SITE,
      });
     if (data?.statusCode == STATUS_CODES.SUCCESS) {
        callback(data);
        yield put(setSiteTitle(data?.data?.site?.title || ""))
        yield put(stopLoading());
      } else {
        // fail(data.msg);
      }
    } catch (error) {
      console.log(error,"error");
    } finally {
      yield put(stopLoading());
    }
}
  
  function* watchCmsSaga() {
    yield all([
       takeLatest(GET_WELCOME_CONTENT,fetchWelcomeCmsContent),
       takeLatest(GET_FOOTER_PAGES_CMS_CONTENT,fetchFooterPagesCmsContent),
       takeLatest(GET_RUNNING_TEXT_CONTENT,fetchRunningText),
       takeLatest(GET_FOOTER_TEXT_CONTENT,fetchFooterText),
       takeLatest(GET_SITE_SETTINGS_CMS_CONTENT,fetchSiteSettingsCmsContent),
      ]);
  }
  
  export default watchCmsSaga;
  