import { SET_SOCKET, SET_SOCKET_MANAGER } from "./constants"

export const setSocket = (payload) => {
    return {
     type : SET_SOCKET,
     payload
    }
 }

export const setSocketManager = (payload) => {
    return {
     type : SET_SOCKET_MANAGER,
     payload
    }
 }