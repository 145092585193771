import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, GameProviders } from "Components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getPromotions } from "Redux/Actions/common";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

const Promotion = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(0);
  const [totalCount, setTotalCount] = useState(1);
  const [activePromotion,setActivePromotion] = useState("");
  // const [data, setData] = useState([
  //   { text: "100% + 10% Share Draw" },
  //   { text: "100% upto $125 + 100 free spins" },
  //   { text: "50% upto $125 + 100 free spins", isFirstDeposit: true },
  //   { text: "80% upto $125 + 100 free spins", isFirstDeposit: true },
  //   { text: "Bonus Regeral Draw 10% Share Draw", isFirstDeposit: true },
  // ]);
  const [data, setData] = useState([]);
  const showDetails = (details) => {
    if(activePromotion == details?._id){
      setActivePromotion("");
    }else{
      setActivePromotion(details?._id);
    }
    // dispatch(savePromotionDetail(details));
    // history.push(ROUTES.PROMOTION_DETAILS);
  };

  const visitLink = (details) => {
    const win = window.open(details?.destinationURL, "_blank");
    if (win != null) {
     win.focus();
    }
  };

  const fetchPromotions = () => {
    let payload = {
      limit: 20,
      skip: pageNo
    }
    dispatch(getPromotions(payload, (response) => {
      // console.log(response, "promotions");
      // setData((prev)=>[...prev,response.data]);
      setData(response?.promotions || []);
      setTotalCount(response.totalCount);
    }))
  }

  useEffect(() => {
    fetchPromotions();
  }, [])

  return (
    <>
      <div className="promotion_sec min-vh-40">
        <div className="container py-4">

          <div style={{
            // height: 300,
            overflowY: "auto",
            overflowX: "hidden",
          }}
          >

              <InfiniteScroll
                dataLength={data.length || 0}
                next={fetchPromotions}
                loader={
                  <div className="loader_text">
                    <span data-loading-text="Loading..."></span>
                  </div>
                }
                hasMore={totalCount > data.length}
                className="accordion"
              >
                {data?.length ? data?.map((item) => (
                  <div className="accordion-item" key={item?._id} data-parent="#accordion" >
                  <div className="accordion-header w-100 d-flex flex-wrap flex-lg-nowrap promotion_items ">
                    <figure className="mb-md-0">
                      <img src={item?.promotionImage} alt="title" width="200" />
                    </figure>
                    <div className="promotion_decription">
                      <div className="row align-items-end h-100">
                        
                        <div className="col-md-12 text-center text-md-end">
                          <Button className="btn btn-md btn-primary" onClick={()=>showDetails(item)} data-target="#collapseOne">
                           {t("see-details")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`accordion-collapse collapse ${activePromotion == item?._id ? "show" : ""} `}>
                     <div dangerouslySetInnerHTML={{__html : item?.description || "" }}></div>
                  </div>
                  </div>

                )) :
                <div className="w-100 text-center">
                  <h3>
                    {t("no-promotions")}
                  </h3>
                </div>
                }</InfiniteScroll>

          </div>
        </div>
      </div>
      <GameProviders />
    </>
  );
};

export default Promotion;
