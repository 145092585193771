import {
  GET_FOOTER_PAGES_CMS_CONTENT,
  GET_FOOTER_TEXT_CONTENT,
  GET_RUNNING_TEXT_CONTENT,
  GET_SITE_SETTINGS_CMS_CONTENT,
  GET_WELCOME_CONTENT,
  SET_FOOTER_TEXT_CONTENT,
  SET_RUNNING_TEXT_CONTENT,
  SET_SITE_TITLE,
  SET_WELCOME_CONTENT,
} from "./constants";

export const fetchWelcomeCmsContent = () => {
  return {
    type: GET_WELCOME_CONTENT,
  };
};

export const setWelcomeCmsContent = (payload) => {
  return {
    type: SET_WELCOME_CONTENT,
    payload,
  };
};

export const fetchRunningText = () => {
  return {
    type: GET_RUNNING_TEXT_CONTENT,
  };
};

export const setRunningText = (payload) => {
  return {
    type: SET_RUNNING_TEXT_CONTENT,
    payload,
  };
};

export const fetchFooterText = () => {
  return {
    type: GET_FOOTER_TEXT_CONTENT,
  };
};

export const setFooterText = (payload) => {
  return {
    type: SET_FOOTER_TEXT_CONTENT,
    payload,
  };
};

export const fetchFooterPagesCmsContent = (payload, callback) => {
  return {
    type: GET_FOOTER_PAGES_CMS_CONTENT,
    payload,
    callback,
  };
};

export const fetchSiteSettingsCmsContent = (callback) => {
  return {
    type: GET_SITE_SETTINGS_CMS_CONTENT,
    callback,
  };
};

export const setSiteTitle = (payload) => {
  return {
    type: SET_SITE_TITLE,
    payload,
  };
};
