import React from "react";

const Headings = ({ data = [], active, handleClick = () => {} }) => {
  return (
    <>
      {data?.map((item, index) => (
        <li key={`121${index}2343`} className={`categories_list_item ${active.active == index + 1 ? "active" : ""}`} onClick={() => handleClick({active : index+1, id : item._id})}>
          {item.categoryName}
        </li>
      ))}
    </>
  );
};

export default Headings;
