export const STRINGS = {
  SOMETHING_WENT_WRONG: "Sorry, something went wrong.",
  OFFLINE_MESSAGE: "You appear to be offline. Please check your internet connection.",
  COPY_SUCCESS: "Link Copied Succesfully",
  NO_TRANSACTIONS: "No Transactions",
  NO_DATA_FOUND: "No data found",
};

export const ERROR = {
  CHECKBOX: "Please accept the terms and conditions",
};
export const NAVIGATION_BAR = {
  DEPOSIT: 1,
  WITHDRAWAL: 2,
  LAST_TRANSACTIONS: 3,
  BONUS_TRANSACTIONS: 4,
  ACCOUNT_INFO: 5,
};
export const LABELS = {
  DEPOSIT: "Deposit",
  WITHDRAWAL: "Withdrawal",
  LAST_TRANSACTIONS: "Last Transactions",
  ACC_INFO: "Account Info",
  CHANGEPWD: "Change Password",
  LOGOUT: "Logout",
  REFERRAL_LINK: "Referral Link",
  LOGIN: "Login",
  REGISTER: "Register",
  REQUEST: "Send Request",
  VERIFY: "Verfiy ",
  ADD_ACCOUNT: "Add Account",
  EDIT_ACCOUNT: "Update Account",
  EDIT: "Edit",
  DELETE: "Delete",
  APPLY: "Apply",
  Welcome: "Welcome to BT2",
  RESET: "Reset",
  CONFIRMATION: "Confirmation",
  CANCEL: "Cancel",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  ADJUSTED: "Adjusted",
  PENDING: "Pending",
  CANCELLED: "Canceled",
  OKAY: "Okay",
  FORGOT_PASSWORD: "Forgot Password?",
  USERNAME_EMAIL: "Username/Email",
  PASSWORD: "Password",
};

export const indexHistory = (index, limit) => {
  if (index == 0) {
    return index + 1;
  } else {
    return index * limit + 1;
  }
};

export const TRANSACTION_TYPE_OPTIONS = [
  { label: "Deposit", value: 1 },
  { label: "Withdrawal", value: 2 },
];

export const TRANSACTION_STATUS = {
  APPROVED: 1,
  REJECTED: 2,
  PENDING: 3,
  CANCELLED: 4
};

export const CONTACT_TYPE = {
  PHONE: 1,
  MESSAGE: 2,
  LINE: 3,
  WHATSAPP: 4,
  WE_CHAT: 5,
  TELEGRAM: 6,
  TWITTER: 7,
  FACEBOOK: 8,
  INSTAGRAM: 9,
  TIKTOK: 10,
  EMAIL : 11
};

export const SOCKET_EVENTS = {
  USER_BALANCE: 'userBalance'
}

export const CMS_SECTION_TYPE = {
  HOW_IT_WORKS: 1,
  ABOUT_US: 2,
  RESPONSIBLE_GAMING: 3,
  HELP_CENTRE: 4,
  TERMS_AND_CONDITION: 5,
  THANK_YOU : 6
};

export const GAME_PLAY_MODES = {
  DEMO : "DEMO",
  REAL : "REAL"
}

export const DEPOSIT_BONUS_TYPE_CODES = {
  FIRST_TIME_DEPOSIT: 1,
  RECURRING_DEPOSIT: 2
};

export const DEPOSIT_BONUS_OPTIONS = [
  {
    label : "First Time Deposit Bonus",
    value : 1
  },
  {
    label : "Recurring Deposit Bonus",
    value : 2
  }
];

export const BONUS_TYPES_CODES = {
  REFERRAL: 1,
  ROLLINGAN: 2,
  CASHBACK: 3,
  DEPOSIT: 4,
  FIRST_TIME_DEPOSIT: 5,
  RECURRING_DEPOSIT: 6
};

export const BONUS_TYPES_OPTIONS = [
  {
    label : "Referral",
    value : 1
  },
  {
    label : "Rollingan",
    value : 2
  },
  {
    label : "Cashback",
    value : 3
  },
  {
    label : "Deposit",
    value : 4
  }
];
