import { SET_SOCKET, SET_SOCKET_MANAGER } from "Redux/Actions/constants";

const initialState = {
    socket : "",
    socketManager : ""
};
const SocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SOCKET : {
        return {
          ...state,
          socket : action.payload
        };
    }
    case SET_SOCKET_MANAGER : {
        return {
          ...state,
          socketManager : action.payload
        };
    }

    default: {
      return state;
    }
  }
};

export default SocketReducer;