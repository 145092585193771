import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";

function Loader() {
  const loader = useSelector((state) => state.loading.loader);

  return loader ? (
    <div className="d-flex align-items-center justify-content-center loader_class">
      <div
        visible="true"
        height="100"
        width="100"
        aria-label="blocks-loading"
        color="green"
        wrapperclass="blocks-wrapper"
        className="blocks-wrapper"
      >
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null;
}

export default Loader;
