export const LOGIN = "LOGIN";
export const FORGOT_PWD = "FORGOT_PWD";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const VERIFY_OTP = "VERIFY_OTP";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const UPDATE_REDUCER_AUTH_TOKEN = "UPDATE_REDUCER_AUTH_TOKEN";
export const SAVE_USER_REGISTER_DETAILS = "SAVE_USER_REGISTER_DETAILS";

export const login = (payload,callback) => {
  return {
    type: LOGIN,
    payload,
    callback
  };
};
export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const forgotPassword = (payload,callback) => {
  return {
    type: FORGOT_PWD,
    payload,
    callback
  };
};
export const register = (payload,callback) => {
  return {
    type: REGISTER,
    payload,
    callback
  };
};

export const changePassword = (payload,callback) => {
  return {
    type: CHANGE_PASSWORD,
    payload,
    callback
  };
};

export const resetPassword = (payload,callback) => {
  return {
    type: RESET_PASSWORD,
    payload,
    callback
  };
};

export const vertifyOTP = (payload,callback) => {
  return {
    type: VERIFY_OTP,
    payload,
    callback
  };
};

export const updateReducerAuthToken = token => {
  return {
      type: UPDATE_REDUCER_AUTH_TOKEN,
      token
  }
}

export const saveUserRegisterDetails = payload => {
  return {
      type: SAVE_USER_REGISTER_DETAILS,
      payload
  }
}
